@import "../../styles/helpers";

.header {
    position: fixed;
    top: 0;
    left: 340px;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 24px 40px;
    background: $n1;
    box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3;
    @include x {
        left: 300px;
        padding: 16px 24px; }
    @include d {
        left: 96px; }
    @include m {
        left: 0;
        padding: 16px 24px 16px 16px; }
    @include dark {
        background: $n7;
        box-shadow: inset 1px 0px 0px $n8, inset 0 -1px 0px $n8; } }

.burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-right: auto;
    @include m {
        display: flex; }
    &:before,
    &:after {
        content: "";
        width: 16px;
        height: 2px;
        border-radius: 2px;
        background: $n4; }
    &:before {
        margin-bottom: 6px; } }

.control {
    display: flex;
    align-items: center;
    margin-left: auto;
    @include m {
        margin-left: 0; } }

.control .button,
.control .buttonmobil,
.control > div,
.buttonSearch {
    &:not(:last-child) {
        margin-right: 24px; } }

.buttonSearch {
    display: none;
    width: 48px;
    height: 48px;
    @include m {
        display: inline-block; }
    svg {
        fill: $n4;
        transition: fill .2s; }
    &.active {
        svg {
            fill: $n7;
            @include dark {
                fill: $n1; } } } }

.control .button {
    @include t {
        display: none; } }

.search {
    @include m {
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        &.visible {
            visibility: visible;
            opacity: 1; } } }

.btns {
    display: flex;
    align-items: center;
    margin-left: auto;
    @include m {
        margin-left: 0; } }

.link {
    margin-right: 32px;
    @include button1;
    color: $n7;
    transition: color .2s;
    @include m {
        margin-right: 24px; }
    @include dark {
        color: $n1; }
    &:hover {
        color: $p1; } }

.buttoncustom {
    cursor: default;
    @include m {
        font-size: 10px; }
    @include a {
        font-size: 9px; } }
