@import "../../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 450px);
        width: calc(100% - 450px);
        padding-right: 8px;
        @include d {
            flex: 0 0 calc(100% - 400px);
            width: calc(100% - 400px); }
        @include t {
            width: 100%;
            padding: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 450px;
        @include d {
            width: 400px; }
        @include t {
            width: auto; } } }

.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }
