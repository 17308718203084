/* SearchField.scss */
.leaflet-control-search {
    position: absolute;
    right: 60px;
    top: 110px;
    z-index: 1800;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
  }
  
 
  
 .leaflet-record-control-search
 {
  position: absolute;
    right: 90px;
    top: 20px;
    z-index: 1800;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
 }