@import "../../styles/helpers";

.field {
    &.fieldIcon {
        .input {
            padding-left: 48px; } }
    &.fieldCurrency {
        .input {
            padding-left: 58px;
            background: none;
            border-color: $n3;
            &:focus {
                border-color: $shades1; }
            @include dark {
                border-color: $n6;
                &:focus {
                    border-color: $n5; } } } }
    &.fieldCopy,
    &.fieldPassword {
        .input {
            padding-right: 48px; } }
    &.fieldError {
        .icon {
            svg {
                fill: $p3;
                transition: fill .2s; } }
        .input {
            padding-right: 48px;
            background: rgba($s1, .25);
            border-color: transparent;
            color: $p3;
            @include dark {
                &:focus {
                    border-color: $p3;
                    color: $p3; } }
            &::placeholder {
                color: $p3; } } }
    &.fieldSuccess {
        .input {
            padding-right: 48px;
            background: $n;
            border-color: $shades1;
            @include dark {
                background: $n8; } } } }

.label {
    margin-bottom: 14px;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.wrap {
    position: relative; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 10px;
    border-radius: 12px;
    border: 2px solid transparent;
    background: $n2;
    @include inter;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $n1; }
    @include placeholder {
        color: $n4;
        @include dark {
            color: $shades1; } }
    &:focus {
        border-color: $shades1;
        background: $n;
        @include dark {
            border-color: $n5;
            background: $n8; } } }

.icon,
.copy,
.password,
.error,
.success {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px; }

.icon {
    left: 0;
    pointer-events: none;
    svg {
        fill: $n4; } }

.copy,
.password {
    right: 0;
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } } }
.error {
    right: 0;
    svg {
        fill: $p3;
        transition: fill .2s; } }
.success {
    right: 0;
    svg {
        fill: $p2;
        transition: fill .2s; } }

.currency {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    padding-top: 1px;
    border-radius: 10px 0 0 10px;
    background: $n2;
    color: $n7;
    @include dark {
        background: $n6;
        color: $n4; } }
