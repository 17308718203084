@import "../../../../styles/helpers";
.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }
.banner {
    margin-bottom: 24px; }

.fieldset {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n6; }
    @include m {
        flex-direction: column; } }

.iconImage {
    width: 20%;
    flex-shrink: 0;
    @include m {
        width: 100%; } }

.details {
    width: 100%;
    display: flex;
    gap: 32px;
    flex-direction: column;
    @include m {
        width: 100%; } }

.description {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include d {
                margin-bottom: 24px; } } } }

.label {
    margin-bottom: 14px;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.wrap {
    cursor: pointer;
    position: relative;
    display: flex;
    height: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: $n2;
    overflow: hidden;
    @include dark {
        background: $n6; }
    &:hover {
        &>.box {
            border: 2px solid $n5;
            &.active {
                opacity: 1; } } } }
.box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 20px;
    border: 2px solid $n3;
    border-radius: 12px;
    background: $n1;
    box-shadow: 0px 12px 13px -6px rgba(0, 0, 0, 0.04);
    @include button1;
    transition: border-color .2s;
    @include dark {
        background: $n7;
        border-color: $n6;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 12px 13px -6px rgba(0, 0, 0, 0.04), inset 0px 2px 4px #31353B; }
    svg {
        margin-right: 8px;
        @include dark {
            fill: $n1; } }
    &.active {
        opacity: 0; } }

.bannerFile {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.iconFile {
    height: 100%;
    width: 100%;
    object-fit: contain; }

.avatar {
    position: relative;
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    margin-right: 16px;
    margin-bottom: 10px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%; } }

.add {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border: 2px solid $n;
    border-radius: 50%;
    background: $p1;
    font-size: 0;
    transition: background .2s;
    svg {
        fill: $n; }
    &:hover {
        background: darken($p1, 5); } }

.row {
    display: flex;
    margin: 0 -8px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px;
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }

.wrapper {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include d {
                margin-bottom: 24px; } } } }

.rowtreecolums {
    display: flex;
    margin: 0 -8px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(33.33% - 16px);
        width: calc(33.33% - 16px);
        margin: 0 8px;
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }

.rowfourcolums {
    display: flex;
    margin: 0 -8px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(25% - 16px);
        width: calc(25% - 16px);
        margin: 0 8px;
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } }
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }

.button {
    margin-left: 5px; }
