@import "../../../../styles/helpers";

.success {
    padding: 60px 0 24px;
    @include m {
        padding: 32px 0 16px; } }

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        width: 100%;
        padding: 0; } }

.field {
    margin: 0 8px 15px 8px; }

.iconcol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

.iconsubcol {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 5px; }

.iconbutton {
    margin: 2px 2px; }

.subcol {
    display: flex; }

.btns {

    margin-top: 10px;
    margin-right: 10px;
    text-align: right; }

.button {
    @include m {
        display: flex;
        width: 100%; } }
