@import "../../styles/helpers";

.controlCustom {
    border-width: 0; }

.head {
    background-color: #f5f5f5; }

.button {
    display: flex;
    width: 100%; }

.outerEditMarker {
    max-width: 550px !important; }
