@import "../../styles/helpers";

.full {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center; }
.foot {
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }
